<template>
  <div class="ranktips">
    <CompHeader title="排行榜" />
    <div class="titlebox">
      <p class="btitle">消费排行榜玩法介绍</p>
      <p class="stitle">当日通过盲盒消费最多的玩家可以获得排行榜奖励</p>
    </div>
    <div class="listbox">
      <div class="listtitle">
        <div class="titleitem">排名</div>
        <div class="titleitem">奖励</div>
      </div>
      <div class="listbody">
        <div class="listitem" v-for="item in listdata" :key="item.id">
          <div class="item">{{ item.title }}</div>
          <div class="item"><CompCoin :num="item.bean" /></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      listdata: [
        {
          id: 1,
          title: "第1名",
          bean: 588,
        },
        {
          id: 2,
          title: "第2名",
          bean: 388,
        },
        {
          id: 3,
          title: "第3名",
          bean: 288,
        },
        {
          id: 4,
          title: "第4名",
          bean: 188,
        },
        {
          id: 5,
          title: "第5名",
          bean: 88,
        },
        {
          id: 6,
          title: "第6-10名",
          bean: 28,
        },
        {
          id: 7,
          title: "第11-20名",
          bean: 18,
        },
        {
          id: 8,
          title: "第21-50名",
          bean: 8,
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.ranktips {
  overflow: hidden;
  padding-top: 0.5rem;
  .titlebox {
    width: 100%;
    height: 0.6rem;
    text-align: center;
    margin-top: 0.12rem;
    .btitle {
      font-weight: 500;
      font-size: 0.16rem;
      color: #ffffff;
      margin-bottom: 0.12rem;
    }
  }
  .listbox {
    width: 3.5rem;
    margin: 0 auto;
    .listtitle {
      width: 100%;
      height: 0.36rem;
      @include bgImg("battle-dialog-bg");
      display: flex;
      align-items: center;
      justify-content: space-between;
      .titleitem {
        width: 50%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 500;
        font-size: 0.12rem;
        color: #ffffff;
      }
    }
    .listbody {
      width: 100%;
      background: #1d1300;
      border: 0.01rem solid #69552d;
      padding-bottom: 0.12rem;
      .listitem {
        width: 3.1rem;
        height: 0.36rem;
        border: 0.01rem solid #69552d;
        @include bgImg("itemlistbg");
        margin: 0.08rem auto 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .item {
          width: 50%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: 400;
          font-size: 0.12rem;
          color: #ffffff;
        }
      }
    }
  }
}
</style>
